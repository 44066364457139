import React from "react"

export interface SelectProps {
  error: any
  question: string
  name: string
  helpText?: string
  options: (string | number)[]
  errorMessage: string
}

const Select = React.forwardRef<any, SelectProps>(
  ({ error, question, name, helpText, options, errorMessage }, ref) => {
    return (
      <div className="mb-6">
        <label
          className={`block text-gray-700 text-sm font-bold ${
            !helpText && "mb-2"
          }`}
          htmlFor={name}
        >
          {question}
        </label>
        {helpText && <p className="text-xs mb-2">{helpText}</p>}
        <select
          name={name}
          ref={ref}
          className="block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
        >
          {options.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        {error[name] && <p className="text-red-600">{errorMessage}</p>}
      </div>
    )
  }
)

export default Select
