import React from "react"

export interface InputProps {
  error: any
  question: string
  name: string
  helpText?: string
  placeholder: string
  errorMessage: string
  textArea?: boolean
}

const Input = React.forwardRef<any, InputProps>(
  (
    { error, question, name, helpText, placeholder, errorMessage, textArea },
    ref
  ) => {
    return (
      <div className="mb-6">
        <label
          className={`block text-gray-700 text-sm font-bold ${
            !helpText && "mb-2"
          }`}
          htmlFor={name}
        >
          {question}
        </label>
        {helpText && <p className="text-xs mb-2">{helpText}</p>}
        {!textArea && (
          <input
            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
            type="text"
            name={name}
            placeholder={placeholder}
            ref={ref}
          />
        )}
        {textArea && (
          <textarea
            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
            name={name}
            placeholder={placeholder}
            ref={ref}
          />
        )}
        {error[name] && <p className="text-red-600">{errorMessage}</p>}
      </div>
    )
  }
)

export default Input
