import React from "react"

export interface CheckboxProps {
  error: any
  question: string | JSX.Element
  name: string
  errorMessage?: string
}

const Checkbox = React.forwardRef<any, CheckboxProps>(
  ({ error, question, name, errorMessage }, ref) => {
    return (
      <div className="mb-6 bg-gradient-to-t">
        <label className="inline-flex space-x-2 my-auto">
          <input
            name={name}
            className="focus:ring-primary-500 h-6 w-6 text-primary-600 border-gray-300 rounded mr-2"
            ref={ref}
            type="checkbox"
          />
          {question}
        </label>
        {error[name] && errorMessage && (
          <p className="text-red-600">{errorMessage}</p>
        )}
      </div>
    )
  }
)

export default Checkbox
