import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Select from "../components/formelements/Select"
import Input from "../components/formelements/Input"
import Checkbox from "../components/formelements/Checkbox"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Button } from "../components/text/Button"
import { H1 } from "../components/text/H1"
import { H2 } from "../components/text/H2"
import { H3 } from "../components/text/H3"
import { Modal } from "../components/Modal"
import { navigate } from "gatsby"
import { OutsideLink } from "../components/text/OutsideLink"
import { Paragraph } from "../components/text/Paragraph"

type Inputs = {
  name: string
  therapy: string
  technologies: string
  homeworkTrue: string
  homework: string
  notifications: string
  problem: string
  emergency: string
  usage: string | number
  patientUsage: string | number
  value: string | number
  newsletter: boolean
  prototyp: boolean
  email: string
  legalRead: boolean
}

export interface FragebogenProps {}

const Fragebogen: React.SFC<FragebogenProps> = () => {
  const { register, handleSubmit, errors } = useForm<Inputs>({
    defaultValues: {
      newsletter: false,
      legalRead: false,
      usage: "Bitte auswählen",
      patientUsage: "Bitte auswählen",
      value: "Bitte auswählen",
      prototyp: false,
    },
  })

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendingError, setSendingError] = useState(false)

  const onSubmit = async (data: Inputs) => {
    setLoading(true)
    setSendingError(false)
    const newsletterData = {
      name: data.name,
      email: data.email,
      newsletter: data.newsletter,
      legalRead: data.legalRead,
      prototyp: data.prototyp,
    }

    const airtableRequest = await fetch(
      "/.netlify/functions/airtableSaveInterviewData",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
    if (airtableRequest.status !== 200) {
      setSendingError(true)
    }

    if (data.prototyp || data.newsletter) {
      const newsletterRequest = await fetch("/.netlify/functions/form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newsletterData),
      })
      if (
        newsletterRequest.status === 500 ||
        newsletterRequest.status === 400
      ) {
        setSendingError(true)
      }
    }

    setShowModal(true)
    setLoading(false)
  }
  return (
    <Layout>
      <Section>
        <div className="lg:w-3/5 xl:w-1/2 mx-auto">
          <Modal
            show={showModal}
            setShow={() => setShowModal(!showModal)}
            content={
              sendingError ? (
                <div>
                  <H2 text="Beim Übermitteln Ihrer Daten ist ein Fehler aufgetreten." />
                  <Paragraph>
                    Bitte versuchen Sie es erneut oder senden uns eine Mail an
                    jonas@diepsychotherapieapp.de.
                  </Paragraph>
                  {sendingError}
                  <Button
                    text="Formular anzeigen"
                    onClick={() => setShowModal(false)}
                  />
                </div>
              ) : (
                <div>
                  <H2 text="Vielen Dank für Ihre Unterstützung!" />
                  <Paragraph>
                    Sie erhalten von uns in Kürze eine E-Mail, in der Sie
                    bestätigen können, dass wir Ihnen Neuigkeiten zusenden
                    dürfen.
                  </Paragraph>
                  <Paragraph>
                    Wenn Sie andere Psychotherapeut*innen kennen, die uns
                    unterstützen könnten, würden Sie uns einen großen Gefallen
                    tun, wenn Sie den Link zur Umfrage weiterleiten würden.
                  </Paragraph>
                  <Paragraph>
                    https://diepsychotherapieapp.de/fragebogen/
                  </Paragraph>
                  <Button text="Zur Startseite" onClick={() => navigate("/")} />
                </div>
              )
            }
          />
          <div className="mb-6">
            <H1 text="Fragebogen Psychotherapie App" />
            <p>
              Vielen Dank, dass Sie sich Zeit für unseren Fragebogen nehmen.
            </p>
          </div>
          <div className="mb-6">
            <H2 text="Unsere Idee:" />
            <p>
              Wir entwickeln eine App, die Therapeut*innen und Patient*innen im
              Therapieprozess unterstützt. Häufig stellen Therapeut*innen ihren
              Patient*innen im Zuge der Therapie Hausaufgaben. Die App soll
              Therapeut*innen bei der Erstellung dieser Hausaufgaben in einem
              digitalen Format unterstützen und den Patient*innen die
              Möglichkeit geben, Hausaufgaben an ihren mobilen Endgeräten zu
              erledigen.
            </p>
          </div>
          <div className="mb-6">
            <H2 text="Unser Konzept" />
            <div className="mb-4">
              <H3 text="für Therapeut*innen:" />
              <ul className="list-disc list-inside">
                <li>
                  können individualisierte Hausaufgaben in folgendem Format
                  erstellen:
                  <ul className="list-square list-inside ml-6">
                    <li>Beschreibungen: Text, Bild, Ton</li>
                    <li>
                      Eingabefelder: Texteingabe, Skalen, Auswahlfelder,
                      Checkboxen
                    </li>
                  </ul>
                </li>
                <li>
                  Hausaufgaben können den jeweiligen Patient*innen zugewiesen
                  werden
                </li>
                <li>
                  einfache Erstellung eines digitalen "Notfallkoffers" für
                  Krisensituationen
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <H3 text="für Patient*innen:" />
              <ul className="list-disc list-inside">
                <li>Möglichkeit Hausaufgaben digital zu bearbeiten</li>
                <li>Erinnerungen für Hausaufgaben aktivieren</li>
                <li>
                  Rufnummern im digitalen Notfallplan sind direkt anwählbar
                </li>
              </ul>
            </div>
            <div>
              <H3 text="Unsere Prämissen:" />
              <ul className="list-disc list-inside">
                <li>Datenschutz und Datensicherheit</li>
                <li>Nutzung von Chiffren zur Patientenzuordnung</li>
                <li>
                  App als Unterstützung und Bestandteil der Therapie - nicht als
                  Ersatz
                </li>
                <li>
                  Nutzung auf allen Endgeräten mit Webbrowser (Smartphone,
                  Tablet, Laptop)
                </li>
              </ul>
            </div>
          </div>
          <p className="mb-6">
            Mit diesem Fragebogen wollen wir von Ihnen als Expert*in eine
            Einschätzung zu Bedarf und Problemen einer solchen App in der
            Psychotherapie finden.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              ref={register({ required: true })}
              name="name"
              error={errors}
              question="Name"
              errorMessage="Bitte geben Sie Ihren vollständigen Namen ein"
              placeholder="Ihr vollständiger Name"
            />
            <Input
              ref={register({ required: true })}
              name="therapy"
              error={errors}
              question="Welcher Therapieschule würden Sie sich am ehesten zuordnen?"
              errorMessage="Bitte geben Sie die Therapieschule an, der Sie sich am ehesten
                zuordnen"
              placeholder="(z.B.
                  kognitive Verhaltenstherapie, Tiefenpsychologie,..)"
            />
            <Input
              ref={register({ required: true })}
              name="technologies"
              error={errors}
              question="Greifen Sie im Zuge der Therapie bereits auf Technologien wie
                beispielsweise Apps zu? Falls ja, welche sind dies?"
              errorMessage='Geben Sie Apps an, welche Sie in der Therapie nutzen. Falls
                Sie keine Nutzen, reicht ein "Nein".'
              placeholder="Nutzen Sie bereits Apps in der Therapie?"
              textArea
            />
            <Input
              ref={register({ required: true })}
              name="homeworkTrue"
              error={errors}
              question="Geben Sie Ihren Patient*innen Hausaufgaben oder Übungen, die
                außerhalb der Therapiesitzung durchzuführen sind?"
              errorMessage="Bitte geben Sie an, ob Sie Ihren Patient*innen Hausaufgaben
                oder Übungen stellen"
              placeholder="Ja/Nein"
            />
            <Input
              ref={register({ required: true })}
              name="homework"
              error={errors}
              question="Können Sie beispielhaft Übungen aufzählen?"
              errorMessage="Bitte geben Sie beispielhaft Übungen an"
              placeholder="Übungen..."
              textArea
            />
            <Input
              ref={register({ required: true })}
              name="frequency"
              error={errors}
              question="Wie oft erhalten Ihre Patient*innen neue Aufgaben, die sie
                außerhalb des Therapiesettings erledigen sollen?"
              errorMessage="Bitte geben Sie an wie häufig Patient*innen neue Aufgaben
                außerhalb des Therapiesettings erledigen sollen"
              placeholder="z.B. jede Therapiesitzung..."
            />
            <Input
              ref={register({ required: true })}
              name="notifications"
              error={errors}
              question="Könnten Sie sich vorstellen, dass Ihre Patient*innen die Aufgabe
                regelmäßiger durchführen würden, wenn sie auf ihrem Smartphone
                erinnert werden würden?"
              errorMessage="Bitte geben Sie an, ob Sie sich vorstellen könnten, dass Ihre
                Patient*innen die Aufgabe regelmäßiger durchführen würden,
                wenn sie auf ihrem Smartphone erinnert werden würden"
              placeholder="Ja, weil.../Nein, weil..."
              textArea
            />
            <Input
              ref={register({ required: true })}
              name="problem"
              error={errors}
              question="Wo könnten nach Ihrer Einschätzung Probleme oder Stolpersteine
                für die Integration einer unterstützenden App im Zuge der
                Psychotherapie liegen?"
              errorMessage="Bitte geben Sie mögliche Probleme oder Stolpersteine an"
              placeholder="Stolperstein 1, Stolperstein 2, ..."
              textArea
            />
            <Input
              ref={register({ required: true })}
              name="emergency"
              error={errors}
              question="In welcher Form stellen Sie Ihren Patient*innen einen
                Notfallplan zur Verfügung?"
              errorMessage="Bitte geben Sie an in welcher Form Sie einen Notfallplan zur
                Verfügung stellen"
              placeholder="z.B. Telefonnummern, Übungen ..."
              textArea
            />
            <Select
              ref={register({ required: true, pattern: /\d/ })}
              name="usage"
              error={errors}
              question="Könnten Sie sich vorstellen eine solche App zu verwenden?"
              helpText="(1: kann ich mir überhaupt nicht vorstellen, 5: kann ich mir auf
                  jeden Fall vorstellen)"
              errorMessage="Bitte geben Sie ob Sie eine solche App verwenden würden"
              options={["Bitte auswählen", 1, 2, 3, 4, 5]}
            />
            <Select
              ref={register({ required: true, pattern: /\d/ })}
              name="patientUsage"
              error={errors}
              question="Könnten Sie sich vorstellen, dass Ihre Patient*innen eine solche App verwenden würden?"
              helpText="(1: kann ich mir überhaupt nicht vorstellen, 5: kann ich mir auf
                  jeden Fall vorstellen)"
              errorMessage="Bitte geben Sie ob Ihre Patient*innen eine solche App verwenden würden"
              options={["Bitte auswählen", 1, 2, 3, 4, 5]}
            />
            <Select
              ref={register({ required: true, pattern: /\d/ })}
              name="value"
              error={errors}
              question="Wie schätzen Sie insgesamt den Mehrwert einer solchen App ein?"
              helpText="(1: sehr gering, 5: sehr hoch)"
              errorMessage="Bitte geben Sie den Mehrwert an, den Ihnen eine solche App bietet"
              options={["Bitte auswählen", 1, 2, 3, 4, 5]}
            />
            <p className="mb-6">
              Dürfen wir Sie erneut kontaktieren, wenn ein erster Prototyp
              exisitiert?
            </p>
            <Checkbox
              ref={register({ required: false })}
              name="prototyp"
              error={errors}
              question="Ich möchte informiert werden, wenn ein Prototyp existiert."
            />
            <p className="mb-6">
              Dürfen wir Sie per E-Mail regelmäßig (ca. 1x im Monat) über den
              aktuellen Status der Psychotherapie-App auf dem Laufenden halten
              (Sie können sich jederzeit wieder abmelden)?
            </p>
            <Checkbox
              ref={register()}
              name="newsletter"
              error={errors}
              question="Ich möchte einmal im Monat den Newsletter erhalten."
            />
            <Input
              ref={register({
                required: true,
                pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
              })}
              name="email"
              error={errors}
              question="E-Mail"
              errorMessage="Bitte geben Sie eine gültige E-Mail an"
              placeholder="Ihre E-Mail Adresse"
            />
            <Checkbox
              ref={register({ required: true })}
              name="legalRead"
              error={errors}
              question={
                <span className="ml-1">
                  Die{" "}
                  <OutsideLink
                    href="http://diepsychotherapieapp.de/datenschutzerklaerung"
                    input="Datenschutzerklärung"
                  />{" "}
                  habe ich gelesen und stimme ihr zu.
                </span>
              }
              errorMessage="Sie müssen der Datenschutzerklärung zustimmen, um das Formular
                zu senden"
            />
            <div className={`mb-6 flex `}>
              <Button text="Senden" className="w-5/12" loading={loading} />
            </div>
          </form>
        </div>
      </Section>
    </Layout>
  )
}

export default Fragebogen
